@import '../../../global.scss';

.change-pwd-modal{
  max-width: 385px !important;
  .modal-body{
    padding: 20px 20px 15px 20px !important;
    .MuiFormControl-root{
      .MuiInputBase-input{
        padding-left: 10px !important;
      }
      .MuiInputLabel-shrink {
        transform: translate(-20px, -5px) scale(0.75) !important;
      }
      .MuiInputLabel-outlined {
        transform: translate(-20px, 20px) scale(1);
      }
    }
  }
  .modal-footer{
    padding: 0px 20px 20px 20px !important;
    border-top: none;
  }
  .modal-custom-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 6px !important;
    .heading-text{
      color: #1c0447;
      font-family: "Montserrat-Medium";
      font-size: 14px !important;
      font-weight: 500;
      line-height: 17px;
    }
    .close-icon{
      cursor: pointer;
    }
  }
}

.change-pwd-modal-header button{
  padding: 0;
  background: transparent;
  border: 0;
}

.change-pwd-modal-header button span{
  font-size: 1.5rem;
}

.change-pwd-input-top fieldset{
  top: 0px;
  border-radius: 5px;
  border: 1px solid #808080;
  border-color: #808080!important;
  border-width: 1px!important;
}

.change-pwd-input-label{
  background: #FFF;
  padding: 0 3px!important;
  color: #808080!important;
  font-family: Montserrat-SemiBold!important;
  font-size: $font13!important;
  font-weight: $font-weight-medium!important;
  line-height: $font18!important;
  text-align: left!important;
  top: -20px!important;
}

.change-pwd-input-val input{
  font-family: Montserrat-SemiBold;
  font-size: $font13;
  font-weight: $font-weight-medium;
  line-height: $font18;
  text-align: left;
  padding: 12px;
}

.change-pwd-input-label.MuiInputLabel-outlined.MuiInputLabel-shrink{
  top: initial!important;
}

.Mui-error fieldset{
  border-color: $COLOR_FF5151!important;
}

.error-msg-color{
  color: $COLOR_FF5151!important;
}

.error-msg-style{
  margin-bottom: 0;
  color: $COLOR_FF5151;
  font-size: $font12;
  font-family: Montserrat-Regular;
  margin-top: -6px;
}