@import '../../../global.scss';

.change-pwd-modal {
  max-width: 445px;

  .modal-content {
    border-radius: 10px;
  }

  .modal-body {
    padding: 20px 20px 0px 20px;

    .MuiFormControl-root {
      .MuiInputBase-input {
        padding-left: 10px !important;
      }

      .MuiInputLabel-shrink {
        transform: translate(-20px, -5px) scale(0.75) !important;
      }

      .MuiInputLabel-outlined {
        transform: translate(-20px, 20px) scale(1);
      }
    }
  }

  .alert-message-box {
    padding: 25px;
    font-family: 'Montserrat';

    .alert-message {
      color: #1c0447;
      font-family: 'Montserrat';
      font-size: 14px;
      font-weight: 600;

    }

    .message {
      color: #1c0447;
      font-size: 14px;
      font-weight: 500;
    }

    .price {
      color: #1c0447;
      font-size: 22px;
      font-weight: 600;

      .duration {
        color: #000000;
        font-size: 13px;
        font-weight: 500;
      }
    }

    .grid {
      display: flex;
      // grid-template-columns: auto auto;
      flex-direction: row;
      // margin: 0;
      gap: 20px;
      // // justify-content: space-evenly;


      .grid-item {
        margin-left: 10px;
      }
    }

    .benefit {
      color: #313131;
      font-size: 13px;
      font-weight: 600;
    }

    .benefit-data {
      color: #313131;
      font-size: 13px;
      font-weight: 500;

    }

    .benefit-counters {
      color: #313131;
      font-size: 13px;
      font-family: 'Montserrat';
      font-weight: 700;
    }

    .auto-renewal-box {
      margin-top: 30px;
      display: flex;

      .radio-btn {
        width: 20px;
        height: 20px;
      }

      .auto-renewal-text {
        padding-left: 14px;
        font-size: 13px;
        font-weight: 500;
      }
    }

  }

  .payment-success-model {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .payment-success-message-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .payment-success-title {
    margin-top: 20px;
    margin-bottom: 10px;
    color: lightgreen;
    font-family: 'Montserrat';
  }

  .plan-amount {
    font-weight: 600;
  }

  .modal-footer {
    padding: 0px 20px 20px 20px !important;
    border-top: none;

    .negative-btn,
    .positive-btn {
      height: 30px;
      border-radius: 5px;
      font-size: 12px;
      margin: 0px;
      font-family: "Montserrat-Medium";
      text-transform: uppercase;
    }

    .negative-btn {
      background-color: #ffffff;
      padding: 0px 23px;
      border: 1px solid #ffffff;
      color: #a58b89;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 18px;
    }

    .positive-btn {
      background-color: #a58b89;
      padding: 0px 23px;
      border: 1px solid #a58b89;
    }
  }

  .modal-custom-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 1px solid $borderColor;

    .heading-text {
      color: #1c0447;
      font-family: "Montserrat-Medium";
      font-size: 14px !important;
      font-weight: 500;
      line-height: 17px;
    }

    .close-icon {
      cursor: pointer;
    }
  }
}



.change-pwd-modal-header button {
  padding: 0;
  background: transparent;
  border: 0;
}

.change-pwd-modal-header button span {
  font-size: 1.5rem;
}

.change-pwd-input-top fieldset {
  top: 0px;
  border-radius: 5px;
  border: 1px solid #808080;
  border-color: #808080 !important;
  border-width: 1px !important;
}

.change-pwd-input-label {
  background: #FFF;
  padding: 0 3px !important;
  color: #808080 !important;
  font-family: Montserrat-Medium !important;
  font-size: $font13 !important;
  font-weight: $font-weight-medium !important;
  line-height: $font18 !important;
  text-align: left !important;
  top: -8px !important;
}

.change-pwd-input-val input {
  font-family: Montserrat-SemiBold;
  font-size: $font13;
  font-weight: $font-weight-medium;
  line-height: $font18;
  text-align: left;
  padding: 12px;
}

.change-pwd-input-label.MuiInputLabel-outlined.MuiInputLabel-shrink {
  top: initial !important;
}

.Mui-error fieldset {
  border-color: $COLOR_FF5151 !important;
}

.error-msg-color {
  color: $COLOR_FF5151 !important;
}

.error-msg-style {
  margin-bottom: 0;
  color: $COLOR_FF5151;
  font-size: $font12;
  font-family: Montserrat-Regular;
  margin-top: 2px;
}

.date-row {
  .col {
    &:first-child {
      padding-right: 5px;
    }

    &:nth-child(2) {
      padding-left: 5px;
      padding-right: 5px;
    }

    &:last-child {
      padding-left: 5px;
    }
  }
}




@media (max-width: 480px) {
  .change-pwd-modal {

    // margin-right: 20px;
    // width: 100px;
    .benifitList {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .grid-box {
      .grid {
        display: flex;
        flex-direction: column;
        gap: 0;

      }
    }
  }


  .grid {
    display: flex;
    // grid-template-columns: auto auto;
    flex-direction: column;
    // // background-color: green;
    // justify-content: flex-start;
    // gap: 0px;

    // margin: 0;

    .grid-item {
      margin-left: 10px;
    }
  }
}