@import '../../../global.scss';

.add-bank-details-wrapper{
    form{
        .row{
            margin: 0;
        }
        .form-item{
            padding: 0;
            
            &:first-child, &:nth-child(3), &:nth-child(5),&:nth-child(7), &:nth-child(9),&:nth-child(11),
             &:nth-child(13), &:nth-child(15), &:nth-child(17){
                padding-right: 7px;
                padding-left: 0px;
            }

            &:nth-child(2), &:nth-child(4),&:nth-child(6), &:nth-child(8), &:nth-child(10), 
            &:nth-child(12), &:nth-child(14), &:nth-child(16){
                padding-left: 7px;
                padding-right: 0px;
            }
            
            .row{
                .col{
                    padding: 0;
                    margin-bottom: 23px;
                }
            }
            .mobile-field{
                >div{
                    padding: 0;
                    &:first-child{
                        padding-right: 7px;
                    }
                    &:last-child{
                        padding-left: 7px;
                    }
                }
            }
        }
    }
    .MuiInputBase-input{
        padding: 8px 16px 12px 10px !important;
    }

    .MuiFormLabel-root.Mui-focused{
        background: $whiteColor !important;
        font-size: $font12 !important;
        color: $labelColor !important;
        padding: 0px 3px !important;
    }
    
    .MuiFormControl-root{
        display: flex !important;
    }
    
    .MuiInputLabel-formControl{
        left: 0px !important; 
        top: -12px !important; 
        font-size: $font13 !important;
        background: $whiteColor !important;
        font-size: $font12 !important;
        color: $labelColor !important;
        padding: 0px 3px !important; 
        font-family: "Montserrat-Medium" !important;
    }
    
    .MuiInputLabel-shrink{
        transform: translate(14px, 0px) scale(0.75) !important; 
    }
    
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline{
        border: 1px solid $labelColor !important;
    }
    
    .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
        border-color: $dangerColor !important;
        top: -5px !important;
    }
    .MuiFormLabel-root.Mui-error{
        color: $dangerColor !important;
    }
    .MuiFormHelperText-root.Mui-error{
        margin-left: 0px !important;
    }
    .MuiSelect-select:focus{
        background: transparent !important;
    }
}

.date-item{
    .MuiInputLabel-formControl{
        padding-bottom: 10px !important;
    }
    .MuiInputBase-input{
        padding-left: 15px !important;
    }
    input{
        background-image: url('.././../../assets/images/calender_background.svg');
        background-repeat: no-repeat;
        background-position: 94% 40%;
    }
}