@import '../../global.scss';

.common-page-wrapper {
    border: 1px solid $borderColor;
    border-radius: 5px;
    height: 100%;

    .common-page-header {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
        background-color: $headerBgColor;
        margin: 0px;
        border-bottom: 1px solid $borderColor;

        .title {
            padding: 16px;
            font-size: $font16;
            color: $primaryColor;
            font-family: "Montserrat-SemiBold";
            font-weight: 600;
        }

        .header-icons {
            padding: 0px 17px 0 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            img {
                cursor: pointer;
            }

            span {
                cursor: pointer;
                font-size: $font13;
                color: $emptyActionBtnColor;
                font-family: "Montserrat-Medium";
                font-weight: 500;
                margin-left: 5px;
            }
        }
    }

    .bank-details-wrapper {
        height: calc(100% - 57px);
        display: flex;
        align-items: center;
        justify-content: center;

        .details-info {
            text-align: center;
            width: 100%;

            .bank-details {
                &>img {
                    margin: 0px 0px 30px;
                }

                .account-no {
                    font-size: $font20;
                    color: $blackColor;
                    font-family: "Montserrat-Medium";
                    font-weight: 500;
                    margin: 0px;
                }

                .bank-name {
                    font-size: $font15;
                    color: $labelColor;
                    font-family: "Montserrat-Medium";
                    font-weight: 500;
                    margin-bottom: 16px;
                }

                .account-holder-info {
                    border-top: 1px solid $borderColor;
                    border-bottom: 1px solid $borderColor;
                    margin: 0px;
                    padding: 6px 0px;

                    .col {
                        padding: 10px 85px;
                        font-size: $font15;
                        color: $labelColor;
                        font-family: "Montserrat-Medium";
                        font-weight: 500;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:first-child {
                            border-right: 1px solid $borderColor;
                        }

                        img {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    .page-content {
        position: relative;
        padding: 17px;
        height: calc(100% - 57px);
        overflow-y: auto;

        
        .addButton{
            width: 60px;
            display: flex;
            justify-content:flex-end;
            align-items: center;
            border: 1px solid #a58b89;
            margin-left: 295px;
            padding: 5px;
            padding-top: 0px;
            padding-bottom: 0px;
            border-radius: 10px;
            margin-top: -5px;
            margin-bottom: 15px;
            
        }
        
        .card-list {
            height: 100%;

            // border: 1px solid $borderColor;
            // border-radius: 6px;
            // padding: 14px 17px 20px 10px;
            .card-item {
                border: 1px solid $borderColor;
                border-radius: 6px;
                padding: 14px 17px 20px 10px;
                margin-bottom: 15px;

                .radio-label {
                    cursor: pointer;
                    font-size: $font13;
                    color: $primaryColor;
                    font-family: "Montserrat-Medium";
                    font-weight: 500;
                    margin-left: 5px;
                }

                .card-content {
                    display: flex;
                    align-items: center;
                    margin-top: 12px;

                    .card-info {
                        margin-left: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        flex: auto;

                        p {
                            margin: 0;
                        }

                        .card-no {
                            font-size: $font15;
                            color: $blackColor;
                            font-family: "Montserrat-Medium";
                            font-weight: 500;
                        }

                        .card-expiry-date {
                            font-size: $font13;
                            color: $labelColor;
                            font-family: "Montserrat-Medium";
                            font-weight: 500;
                        }

                        .delete-icon {
                            display: flex;
                            align-items: center;
                            justify-content: flex-end;
                            cursor: pointer;
                        }
                        
                    }

                }
            }
        }

        .bank-details-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100% - 57px);

            .details-info {
                text-align: center;
                height: 100%;

                .bank-details {
                    &>img {
                        margin: 0px 0px 30px;
                    }

                    .account-no {
                        font-size: $font20;
                        color: $blackColor;
                        font-family: "Montserrat-Medium";
                        font-weight: 500;
                        margin: 0px;
                    }

                    .bank-name {
                        font-size: $font15;
                        color: $labelColor;
                        font-family: "Montserrat-Medium";
                        font-weight: 500;
                        margin-bottom: 16px;
                    }

                    .account-holder-info {
                        border-top: 1px solid $borderColor;
                        border-bottom: 1px solid $borderColor;
                        margin: 0px;
                        padding: 6px 0px;

                        .col {
                            padding: 10px 85px;
                            font-size: $font15;
                            color: $labelColor;
                            font-family: "Montserrat-Medium";
                            font-weight: 500;
                            text-transform: capitalize;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &:first-child {
                                border-right: 1px solid $borderColor;
                            }

                            img {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}



@media (max-width: 480px) {
    .common-page-wrapper {
        border: none;
        height: auto;
        justify-content: center;
        align-items: center;
        // width: "100%";
        // margin-right: 0px;
    }

    .page-content {
        width: 390px;
    }

    .NoCards {
        border: 2px solid $borderColor;
        padding-bottom: 20px;
        padding-top: 20px;
        
    }
}