/*************** Common color variables **************/
$whiteColor:                           #ffffff;    
$blackColor:                           #000000;
$labelColor:                           #808080;
$headingColor:                         #272730;
$primaryColor:                         #0f0643;
$dangerColor:                          #f44336;
$COLOR_FF5151:                         #FF5151;
$borderColor:                          #ebebeb;
$headerBgColor:                        #f9f9f9;
$COLOR_1C0447:                         #1C0447;
$COLOR_F1F1F1:                         #F1F1F1;
$emptyActionBtnColor:                  #a58b89;
$redBtnColor:                          #f15642;