@import '../../../global.scss';

.change-pwd-modal {
  max-width: 445px;

  .modal-content {
    border-radius: 10px;
  }

  .modal-body {
    padding: 20px 20px 0px 20px;

    .MuiFormControl-root {
      .MuiInputBase-input {
        padding-left: 10px;
      }

      .MuiInputLabel-shrink {
        transform: translate(-20px, -5px) scale(0.75);
      }

      .MuiInputLabel-outlined {
        transform: translate(-20px, 20px) scale(1);
      }
    }
  }

  .modal-footer {
    padding: 0px 20px 20px 20px;
    border-top: none;

    .negative-btn,
    .positive-btn {
      height: 30px;
      border-radius: 5px;
      font-size: 12px;
      margin: 0px;
      font-family: "Montserrat-Medium";
      text-transform: uppercase;
    }

    .negative-btn {
      background-color: #ffffff;
      padding: 0px 23px;
      border: 1px solid #ffffff;
      color: #a58b89;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 18px;
    }

    .positive-btn {
      background-color: #a58b89;
      padding: 0px 23px;
      border: 1px solid #a58b89;
    }
  }

  .modal-custom-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;

    .heading-text {
      color: #1c0447;
      font-family: "Montserrat-Medium";
      font-size: 14px;
      // font-size: 10px;
      font-weight: 500;
      line-height: 17px;
    }

    .close-icon {
      cursor: pointer;
    }
  }
}

.change-pwd-modal-header button {
  padding: 0;
  background: transparent;
  border: 0;
}

.change-pwd-modal-header button span {
  font-size: 1.5rem;
 
}

.change-pwd-input-top fieldset {
  top: 0px;
  border-radius: 5px;
  border: 1px solid #808080;
  border-color: #808080;
  border-width: 1px;
  // font-size: 10px;
}

.change-pwd-input-label {
  background: #FFF;
  padding: 0 3px;
  color: #808080;
  font-family: Montserrat-Medium;
  font-size: $font13;
  font-weight: $font-weight-medium ;
  line-height: $font18!important;
  text-align: left;
  top: -8px;
}

.change-pwd-input-label1 {
  background: #FFF;
  padding: 0 3px;
  color: #808080;
  font-family: Montserrat-Medium;
  font-size: $font13;
  font-weight: $font-weight-medium ;
  line-height: $font18!important;
  text-align: left;
  top: -8px;
  margin-right: -40px;
}

.inputFiled{
  background: #FFF;
  padding: 0 3px;
  // margin-left: -15px;
  margin-right: -40px;
  color: #808080;
  font-family: Montserrat-Medium;
  font-size: $font13;
  font-weight: $font-weight-medium ;
  text-align: left;
  top: -14px;
}
.change-pwd-input-val input {
  font-family: Montserrat-SemiBold;
  font-size: $font13;
  font-weight: $font-weight-medium;
  line-height: $font18;
  text-align: left;
  padding: 12px;
}

.change-pwd-input-label.MuiInputLabel-outlined.MuiInputLabel-shrink {
  top: initial;
}

.Mui-error fieldset {
  border-color: $COLOR_FF5151;
}

.error-msg-color {
  color: $COLOR_FF5151;
}

.error-msg-style {
  margin-bottom: 0;
  color: $COLOR_FF5151;
  font-size: $font12;
  font-family: Montserrat-Regular;
  margin-top: 2px;
}

.date-row {
  .col {
    &:first-child {
      padding-right: 5px;
    }

    &:nth-child(2) {
      padding-left: 5px;
      padding-right: 5px;
    }

    &:last-child {
      padding-left: 5px;
    }
  }
}



@media (max-width: 480px) {
  .error-msg-style {
    font-size: 8px;
  }

  
}