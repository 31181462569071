@import '../../global.scss';

.info-model {
    position: absolute;
    top: 21.5%;
    right: 12.5%;

    .polygon {
        position: relative;
        // top: 2%;
        left: 73%;
    }

    .info-box {
        width: 400px;

        border-radius: 19px;
        background-color: #272727;

        .content {
            padding: 10px;
            padding-top: 0px;

            .info-item {
                color: #ffffff;
                font-family: 'Montserrat';
                font-size: 13px;
                font-weight: 600;
            }

            .list {
                list-style: none;
            }

            .list li:not(:last-child) {
                margin-bottom: 10px;
            }


        }
    }

    .cross-icon {
        display: flex;
        justify-content: flex-end;
        padding: 14px;

        img {
            cursor: pointer;
        }
    }

}



.common-page-wrapper {
    // border: 1px solid $borderColor;
    border-radius: 5px;
    // height: 100%;
    // width: 100px;

    .common-page-header {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        margin: 0px;
        border-bottom: 1px solid $borderColor;

        .title {
            padding: 14px;
            font-size: $font16;
            color: $primaryColor;
            font-family: "Montserrat-SemiBold";
            font-weight: 600;
            // background-color: #ebebeb;
            // background-color: $headerBgColor;

        }

    }

    .bank-details-wrapper {
        height: calc(100% - 57px);
        display: flex;
        align-items: center;
        justify-content: center;

        .details-info {
            text-align: center;
            width: 100%;

            .bank-details {
                &>img {
                    margin: 0px 0px 30px;
                }

                .account-no {
                    font-size: $font20;
                    color: $blackColor;
                    font-family: "Montserrat-Medium";
                    font-weight: 500;
                    margin: 0px;
                }

                .bank-name {
                    font-size: $font15;
                    color: $labelColor;
                    font-family: "Montserrat-Medium";
                    font-weight: 500;
                    margin-bottom: 16px;
                }

                .account-holder-info {
                    border-top: 1px solid $borderColor;
                    border-bottom: 1px solid $borderColor;
                    margin: 0px;
                    padding: 6px 0px;

                    .col {
                        padding: 10px 85px;
                        font-size: $font15;
                        color: $labelColor;
                        font-family: "Montserrat-Medium";
                        font-weight: 500;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:first-child {
                            border-right: 1px solid $borderColor;
                        }

                        img {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }


    .page-content {
        position: relative;
        padding: 17px;
        height: calc(100% - 57px);

        .active-plan-box {

            padding-bottom: 0px;

            overflow: auto;
            border-radius: 5px;
            border: 1px solid $borderColor;
            margin-bottom: 5px;

            .active-plan-header {
                border-top-left-radius: 6px;
                border-top-right-radius: 6px;
                background-color: $headerBgColor;
                padding-left: 17px;
                padding-top: 5px;
                padding-bottom: 5px;
                padding-right: 17px;


                .title {

                    font-size: 15px;
                    color: #0f0643;
                    padding-top: 5px;
                    font-family: "Montserrat-SemiBold";
                    font-weight: 600;
                }

                .header-icons {

                    display: flex;
                    align-items: center;
                    // margin-bottom: 10px;
                    gap: 10px;
                    justify-content: flex-end;
                    align-self: center;

                    img {
                        cursor: pointer;

                    }

                    .subscription-btn {
                        border-radius: 5px;
                        background-color: #a58b89;
                        color: #ffffff;
                        width: 107px;
                        height: 26px;
                        font-family: 'Poppins';
                        font-size: 12px;
                        font-weight: 500;

                    }

                    .auto-renewal-btn {
                        /* Style for "Rectangle" */
                        text-align: center;
                        margin-right: 10px;
                        color: #a58b89;
                        font-family: Poppins;
                        font-size: 12px;
                        font-weight: 500;
                        background-color: #ffffff;
                        border-radius: 5px;
                        border: 1px solid #a58b89;
                    }

                    #info-icon {
                        // background-color: red;
                        padding-bottom: 6px;
                    }


                    .subscription-btn:hover,
                    .auto-renewal-btn:hover {
                        // background-color: transparent;
                        background-color: #a58b89;
                        color: #ffffff;
                    }
                }

            }

            .row1 {
                display: flex;
                padding-left: 17px;
                padding-top: 10px;
                padding-right: 17px;
                padding-bottom: 0px;

                gap: 14px;

                .radio-btn {
                    width: 20px;
                    height: 20px;
                }

                .subscription-name {
                    color: #1c0447;
                    font-family: Montserrat;
                    font-size: 16px;
                    font-weight: 600;

                }
            }


            .subscription-plan-detail {
                // padding: 0px 11px 0 0;
                padding-left: 17px;

                padding-top: 5px;
                border-bottom: 1px solid $borderColor;

                .price-list {
                    display: flex;
                    // flex-direction: row;
                    gap: 35px;
                    // justify-content: space-evenly;
                    // margin-left: -100px;
                    // columns: 3;
                    // width: 900px;
                }

                .radio-btn {
                    width: 20px;
                    height: 20px;
                    cursor: pointer;
                }

                .grid-container {
                    display: flex;
                    // grid-template-columns: auto auto auto;
                    margin: 0;
                    flex-direction: row;
                    gap: 30px;

                    // gap: 40px;
                    .grid-item {
                        margin-left: 10px;

                    }
                }

                .price {
                    color: #1c0447;
                    font-family: 'Montserrat';
                    font-size: 22px;
                    font-weight: 600;
                    margin-left: 14px;
                }

                .type {
                    font-family: 'Montserrat';
                    font-size: 13px;
                    font-weight: 500;
                }

                .benefits {
                    color: #313131;
                    font-family: 'Montserrat';
                    font-size: 15px;
                    font-weight: 600;
                }

                .expiry_date {
                    color: #646464;
                    font-family: 'Montserrat';
                    font-size: 13px;
                    font-weight: 500;
                    padding-right: 17px;

                }

                .description {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;

                }

                .benefits-data {
                    color: #313131;
                    font-family: ' Montserrat';
                    font-size: 13px;
                    font-weight: 500;
                    padding-left: 0px;
                    display: inline;


                }

                .benefit-counters {
                    color: #313131;
                    font-size: 17px;
                    font-weight: 600;
                    // background-color: red;
                    font-family: 'Montserrat';
                }

            }

            .plan-detail {
                // padding: 0px 11px 0 0;
                padding-left: 50px;

                border-bottom: 1px solid $borderColor;

                .grid-container {
                    // display: grid;
                    columns: 3;
                    grid-template-columns: auto auto auto;
                    margin: 0;

                    .grid-item {
                        margin-left: 10px;

                    }
                }

                .price {
                    color: #1c0447;
                    font-family: 'Montserrat';
                    font-size: 22px;
                    font-weight: 600;
                }

                .type {
                    font-family: 'Montserrat';
                    font-size: 13px;
                    font-weight: 500;
                }

                .benefits {
                    color: #313131;
                    font-family: 'Montserrat';
                    font-size: 15px;
                    font-weight: 600;

                }


                .expiry_date {
                    color: #646464;
                    font-family: 'Montserrat';
                    font-size: 13px;
                    font-weight: 500;
                    padding-right: 17px;
                    margin-top: 5px;
                    margin-bottom: 5px;
                    // margin-left: 200px;
                }

                .description {
                    display: flex;
                    columns: 2;
                    flex-direction: row;
                    justify-content: space-between;
                    margin-bottom: 10px;


                }

                .benefits-data {
                    color: #313131;
                    font-family: ' Montserrat';
                    font-size: 13px;
                    font-weight: 500;
                    padding-left: 0px;
                    display: inline;

                }

                .benefit-counters {
                    color: #313131;
                    font-size: 17px;
                    font-weight: 600;
                    // background-color: red;
                    font-family: 'Montserrat';
                }


            }
        }

        .active-plan-box::-webkit-scrollbar {
            display: none;
        }

        .bank-details-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            height: calc(100% - 57px);

            .details-info {
                text-align: center;
                height: 100%;

                .bank-details {
                    &>img {
                        margin: 0px 0px 30px;
                    }

                    .account-no {
                        font-size: $font20;
                        color: $blackColor;
                        font-family: "Montserrat-Medium";
                        font-weight: 500;
                        margin: 0px;
                    }

                    .bank-name {
                        font-size: $font15;
                        color: $labelColor;
                        font-family: "Montserrat-Medium";
                        font-weight: 500;
                        margin-bottom: 16px;
                    }

                    .account-holder-info {
                        border-top: 1px solid $borderColor;
                        border-bottom: 1px solid $borderColor;
                        margin: 0px;
                        padding: 6px 0px;

                        .col {
                            padding: 10px 85px;
                            font-size: $font15;
                            color: $labelColor;
                            font-family: "Montserrat-Medium";
                            font-weight: 500;
                            text-transform: capitalize;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            &:first-child {
                                border-right: 1px solid $borderColor;
                            }

                            img {
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
}




@media (max-width: 480px) {
    .common-page-wrapper {
        // display: none;
        // position: relative;
        height: 100%;
        width: 100%;
         margin-top: -15px;
        // margin-left: -145px;
        border: 0px;
        border-width: 0px;
        border: none;
        // margin-left: 10px;
        // display: none;
        padding: 0;

        .page-content {
            .active-plan-box {
                .plan-detail {
                    .benefits-data {
                        // color: #313131;
                        // font-family: ' Montserrat';
                        // font-size: 13px;
                        // font-weight: 500;
                        // padding-left: 0px;
                        // display: inline;
                        display: block;
                        margin-bottom: -0px;
                        padding: 0;

                    }

                    .description {
                        display: block;
                        columns: 1;
                        // flex-direction: row;
                        // justify-content: space-between;
                        margin-bottom: 10px;


                        .expiry_date {
                            // display: none;
                        }
                    }
                }

                .subscription-plan-detail {

                    .grid-container {
                        display: flex;
                        align-items: flex-start;
                        // grid-template-columns: auto auto auto;
                        margin-top: 7px;
                        // margin: 0;
                        flex-direction: column;
                        // gap: 30px;

                        // gap: 40px;
                        .subscriptionPlanArragmentforMobile {
                            margin-top: -30px;
                        }

                        .grid-item {
                            margin-left: 10px;
                        }

                    }

                    .benefits1 {
                        margin-top: -17px;
                    }
                }

            }
        }

        .common-page-header {
            display: none;
        }

        .auto-renewal-btn {
            display: none;
        }

        .subscription-btn {
            display: none;
        }

        #unsubscribe_img_btn {
            display: none;
        }

        #info-icon {
            display: none;
        }

        .title {
            height: 25px;
            padding: 10px;
        }

        .row1 {
            justify-content: flex-start;
            align-items: center;
        }

        .grid-container {
            flex-direction: column;
            display: flex;
            columns: 1;

        }

        // .description {
        //     flex-direction: column;
        //     display: contents;
        //     // columns: 0;
        //     margin-bottom: 10px;
        // }

        #info-icon {
            display: none;
        }

        .subscription-btn1 {
            border-radius: 5px;
            background-color: #a58b89;
            color: #ffffff;
            width: 120px;
            height: auto;
            font-family: 'Poppins';
            font-size: 15px;
            font-weight: 500;
            padding: 7px;
        }

        .subscription-btn2 {
            border-radius: 5px;
            background-color: #a58b89;
            color: #ffffff;
            width: 130%;
            height: auto;
            font-family: 'Poppins';
            font-size: 15px;
            font-weight: 500;
            padding: 7px;
        }

        .auto-renewal-btn1 {
            /* Style for "Rectangle" */


            text-align: center;
            margin-right: 3px;
            color: #a58b89;

            font-size: 12px;
            font-weight: 500;
            background-color: #ffffff;
            border-radius: 5px;
            border: 1px solid #a58b89;

            border-radius: 5px;
            padding: 5px;
            width: 150px;
            height: auto;
            font-family: 'Poppins';
            // padding-left: 10px;
            // padding-right: 10px;
            font-weight: 500;
        }

        #unsubscribe_img_btn1 {
            display: flex;
        }

        #info-icon1 {
            // background-color: red;
            align-self: center;

            // margin-left: 10px;
        }

        .plan-detail {
            border-bottom: none;
            border-width: 0px;
            border: none;

        }

        .subscription-box1 {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;
            // position: absolute;
            flex-direction: row;
            margin-bottom: 15px;
            // align-items: center;
            // columns: 3;
            margin-left: 10px;
            margin-right: 10px;
        }

        .active-plan-header {
            height: auto;
            // justify-content: center;
            // align-items: center;
        }

        .item-div {
            .subscription-plan-detail {
                .price-list {
                    flex-direction: column;
                    display: flex;
                    margin-bottom: 40px;
                    // margin-left: -200px;
                }

                .priceList2 {
                    margin-bottom: -28px;
                }

                .grid-container {
                    flex-direction: column;
                    display: flex;
                    columns: 1;

                }
            }
        }
    }

    .info-model {
        position: absolute;
        top: 16%;
        // right: 2%;
        left: 10%;


        .polygon {
            // display: none;
            position: absolute;
            // right: 70%;
            // top: 15%;
            left: 90%;
        }

        .info-box {
            width: 330px;
            border-radius: 19px;
            background-color: #272727;
        }
    }

}