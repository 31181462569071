.top-bar-logo {
  top: 50%;
  transform: translateY(-50%);
  position: relative;
  margin-left: 10px;
}
.right-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .nav {
    .nav-link {
      padding: 7px 10px !important;
      color: #a08687;
      font-family: Montserrat-Medium;
      font-size: 12px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: normal;
      line-height: 18px;
      cursor: pointer;
    }
  }
  .selected-nav-item {
    .nav-link {
     span{
      border-bottom: 2px solid #ffffff;
      color: #ffffff;
     }
    }
  }
  .profile-avatar {
    width: 30px !important;
    height: 30px !important;
    margin-left: 30px;
    border-radius: 50%;
  }
}


@media (max-width: 480px) {     // media query for mobile


  
  .right-section {
    display: none;
  }

  .top-bar-logo {
    display: none;
  }
  .hamberger-button{
    display: none;
    // padding-left: -20px;
  }
  
}


