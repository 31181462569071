@import '../../global.scss';

.settings-wrapper{
    border: 1px solid $borderColor;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 100%;
    .heading-container{
        background-color: $headerBgColor;
        height: 51px;
        color: $primaryColor;
        font-family: "Montserrat-Medium";
        font-weight: 600;
        font-size: $font16;
        text-align: left;
        padding-left: 17px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $borderColor;
    }
    .settings-list-container{
        width: 100%;
        .setting-item{
            width: 100%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 17px;
            border-bottom: 1px solid $borderColor;
            cursor: pointer;
            .item-name{
                color: $blackColor;
                font-family: "Montserrat-Medium";
                font-size: $font14;
                font-weight: 500;
                line-height: 18px;
                text-align: left;
            }
        }
    }
}

.Mui-checked{
    left: 0px !important;
}
.MuiSwitch-switchBase{
    left: -24px !important;
}
.MuiSwitch-track{
    background: #d8d8d8 !important;
}

@media (max-width: 480px) {
    .settings-wrapper{
        border: 1px solid $borderColor;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        // height: 100%;
        width: 100%;
        // background-color: green;
        .heading-container{
            background-color: $headerBgColor;
            height: 200x;
            width: 100%;
            color: $primaryColor;
            font-family: "Montserrat-Medium";
            font-weight: 600;
            font-size: $font16;
            text-align: left;
            padding-left: 17px;
            display: flex;
            align-items: left;
            border-bottom: 1px solid $borderColor;
        }
        .settings-list-container{
            width: 100%;
            .setting-item{
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 0px 17px;
                border-bottom: 1px solid $borderColor;
                cursor: pointer;
                .item-name{
                    color: $blackColor;
                    font-family: "Montserrat-Medium";
                    font-size: $font14;
                    font-weight: 500;
                    line-height: 18px;
                    text-align: left;
                }
            }
        }
    }
}