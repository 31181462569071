/****************Common font variables *********************/
$font10: 10px;
$font11: 11px;
$font12: 12px;
$font13: 13px;
$font14: 14px;
$font15: 15px;
$font16: 16px;
$font17: 17px;
$font18: 18px;
$font20: 20px;
$font22: 22px;
$font24: 24px;
$font26: 26px;
$font28: 28px;
$font30: 30px;
$font32: 32px;
$font34: 34px;
$font36: 36px;
$font38: 38px;