@import "../../global.scss";

.drawer-profile {
    background-color: #f9f9f9;
    border-bottom: 1px solid #ebebeb;
}

.drawer-profile-img {
    height: auto;
    width: auto;
    max-height: 60px;
    max-width: 60px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: relative;
}

.drawer-profile-img-div {
    height: 60px;
    width: 60px;
    min-width: 60px;
    background: $COLOR_F1F1F1;
    border-radius: 30px;
    overflow: hidden;
}

.drawer-profile-name {
    color: $COLOR_1C0447;
    font-family: Montserrat-SemiBold;
    font-size: $font16;
    font-weight: $font-weight-medium;
    line-height: $font18;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 154px;
}

.drawer-profile-desi {
    color: $labelColor;
    font-family: Montserrat-SemiBold;
    font-size: $font12;
    font-weight: $font-weight-medium;
    line-height: $font18;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 154px;
    margin-top: 5px;
}

.drawer-profile-name-align {
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
}

.logout-text {
    .MuiTypography-root {
        color: #f15642 !important;
    }
}

.MuiListItemText-primary {
    color: #808080;
    font-family: 'Montserrat-Medium' !important;
    font-size: $font13 !important;
    font-weight: 500;
    font-style: normal;
    letter-spacing: normal;
    line-height: 18px;
}


.selected-menu {
    background-color: #e8e8e8 !important;

    .MuiListItemText-root {
        .MuiTypography-root {
            color: #0f0643 !important;
            font-family: 'Montserrat-SemiBold' !important;
            font-size: $font13;
            font-weight: 500;
        }
    }
}

// .MuiButtonBase-root
.MuiListItem-root {
    padding-left: 25px !important;
    cursor: pointer;

    &:hover {
        background-color: #e8e8e8 !important;
    }
}


.MuiListItemIcon-root {
    min-width: 46px !important;
}

.menu-list-custom {
    height: calc(100vh - 158px);
    width: 100% !important;
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.list-button-wrapper {
    display: flex !important;
    width: 100% !important;
}

.MuiPaperRoot {
    margin-left: '15vw';
    margin-top: '65px';
    z-index: '999';
    border-radius: 5;
    border: '1px solid #ebebeb';
    box-shadow: '0 3px 6px rgba(0, 0, 0, 0.16)';

}

@media (max-width: 480px) {
    .drawer-profile {
        display: none;
    }

    .mainDrawerCSS {
        display: none;
    }

    #drawerVisible {
        display: none; //.................drower visible
        // margin-left: 100px;

    }

    .menu-list-custom {
        height: calc(1020vh - 18px);
        width: 100% !important;
        overflow-y: auto !important;
        overflow-x: hidden !important;
    }

    .mobileDrawer {
        transition: opacity 1s;
        // opacity: 1;
    }
}