@import "../../global.scss";

.common-auth-wrapper {
  .row {
    margin-left: 0;
    margin-right: 0;
  }
  .bg-image {
    background: url("../../assets/bg.jpg") no-repeat;
    background-size: cover;
    background-position: center; // 100%
    width: 100%;
    height: 99.99vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      // position: absolute;
      // top: 39%;
      // left: 30%;
      background: url("../../assets/AuthScreen_Logo.svg") no-repeat;
      background-size: contain;
      background-position: 100% 100%;
      width: 235px;
      height: 135px;
    }
  }
  .common-reg-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .title {
      font-size: $font30;
      color: $headingColor;
      margin: 0px;
      font-family: "Montserrat-Bold";
      text-transform: uppercase;
    }
  }
  .form-wrapper {
    padding: 0px 110px;
    width: 100%;
    text-align: center;
  }
  .subtext {
    font-size: $font13;
    color: $labelColor;
    // margin: 0 0 28px;
    font-family: "Montserrat-Medium";
  }
  .text-item {
    position: relative;
    margin-bottom: 23px;
    .text-icon {
      position: absolute;
      left: 16px;
      top: 7px;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
    .text-field {
      position: relative;
    }
  }

  .signup-button {
    margin-bottom: 22px;
  }

  .links {
    text-align: right;
    margin-bottom: 15px;
  }

  .password-field {
    margin-bottom: 15px;
  }

  .link-text {
    font-size: $font14;
    color: $primaryColor;
    text-transform: uppercase;
    font-family: "Montserrat-Medium";
    text-decoration: none;
    font-weight: 600;
  }

  .hide-pwd {
    background: url("../../assets/eye_closed.svg") no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 7px;
    cursor: pointer;
  }

  .show-pwd {
    background: url("../../assets/eye_open.svg") no-repeat;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 10px;
    top: 7px;
    cursor: pointer;
  }

  .otp-container {
    justify-content: space-between;
    align-items: center;
    .otp-input {
      align-items: center;
      justify-content: center;
      font-size: $font15;
      color: $blackColor;
      font-family: "Montserrat-Bold";
      width: 44px !important;
      height: 44px;
    }
  }
}

.MuiFormLabel-root.Mui-focused {
  background: $whiteColor !important;
  font-size: $font12 !important;
  color: $labelColor !important;
  padding: 0px 3px !important;
}

.MuiFormControl-root {
  display: flex !important;
}

.MuiInputLabel-formControl {
  left: 28px !important;
  top: -9px !important;
  font-size: $font13 !important;
  background: $whiteColor !important;
  font-size: $font12 !important;
  color: $labelColor !important;
  padding: 0px 3px !important;
  font-family: "Montserrat-Medium" !important;
}

.MuiInputLabel-shrink {
  transform: translate(14px, 0px) scale(0.75) !important;
}

.MuiInputBase-input {
  padding: 8px 16px 12px 44px !important;
  font-size: $font13 !important;
  color: $blackColor !important;
  font-weight: 500;
  height: unset !important;
  font-family: "Montserrat-Medium" !important;
  text-align: initial;
}

.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid $labelColor !important;
}

.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: $dangerColor !important;
  top: -5px !important; // 0px
}
.MuiFormLabel-root.Mui-error {
  color: $dangerColor !important;
}
.MuiFormHelperText-root.Mui-error {
  margin-left: 0px !important;
}
.MuiSelect-select:focus {
  background: transparent !important;
}

.role-dropdown {
  
  .dd-item {
    font-size: 13px !important;
    font-family: "Montserrat-Medium" !important;
  }
}
.MuiPopover-paper {
    margin-top: 8px;
  }

  @media (max-width: 480px) {
    .common-auth-wrapper {
      .bg-image {
        background: url("../../assets/bg.jpg") no-repeat;
        background-size: cover;
        background-position: center; // 100%
        width: 100%;
        height: 29.99vh;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .logo {
          // position: absolute;
          // top: 39%;
          // left: 30%;
          background: url("../../assets/AuthScreen_Logo.svg") no-repeat;
          background-size: contain;
          background-position: 100% 100%;
          width: 235px;
          height: 135px;
        }
      }
      .text-item {
        position: relative;
        margin-bottom: 23px;
        .text-icon {
          position: absolute;
          left: 16px;
          top: 7px;
          width: 16px;
          height: 16px;
          display: flex;
          align-items: flex-end;
          justify-content: center;
        }
        .text-field {
          position: relative;
          width: 200px;
          // background-color: green;
        }
      }
  
    }
    .role-dropdown {
      width: 200px;
      .dd-item {
        font-size: 13px !important;
        font-family: "Montserrat-Medium" !important;
      }
    }
    .signup-button {
      margin-bottom: 22px;
      width: 200px;
    }
      }

 